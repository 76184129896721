import React from 'react'
import ClockSVG from '../assets/images/svg/time.svg';

export type NewsDatetimeProps = {
    date: string,
}

export const NewsDatetime: React.FC<NewsDatetimeProps> = ({ date }) => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
        timeZone: "Pacific/Auckland",
        day: "numeric",
        month: "short",
        year: "numeric",

    }

    const dateFormatted = new Date(date).toLocaleDateString('en-NZ', dateFormatOptions)
   

    return (
        <time dateTime={date} className="NewsDatetime">
            <ClockSVG />
            <span>{dateFormatted}</span>
        </time>
    );
}