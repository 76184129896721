import React from 'react'
import ClockSVG from '../assets/images/svg/time.svg';

export type EventDatetimeProps = {
    startDate: string,
    endDate: string
}

export const EventDatetime: React.FC<EventDatetimeProps> = ({ startDate, endDate }) => {
    const dateFormatOptions: Intl.DateTimeFormatOptions = {
        timeZone: "Pacific/Auckland",
        day: "numeric",
        month: "short",
        year: "numeric",

    }
    const timeFormatOptions: Intl.DateTimeFormatOptions = {
        timeZone: "Pacific/Auckland",
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
    }

    const startDateFormatted = new Date(startDate).toLocaleDateString('en-NZ', dateFormatOptions)
    const endDateFormatted = endDate && new Date(endDate).toLocaleDateString('en-NZ', dateFormatOptions)
    const startDateString = `${startDateFormatted} ${new Date(startDate).toLocaleTimeString('en-NZ', timeFormatOptions)}`
    const endDateString = `${startDateFormatted === endDateFormatted ? '' : endDateFormatted}  ${new Date(endDate).toLocaleTimeString('en-NZ', timeFormatOptions)}`

    return (
        <p className='EventDatetime'>
            <ClockSVG />
            <time dateTime={startDate}>{startDateString}</time>
            <time dateTime={endDate}>- {endDateString}</time>
        </p>
    );
}